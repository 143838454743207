import React from "react"
import cx from "classnames"
import LazyLoad from "react-lazyload"
import htmlParser from "react-html-parser"
import is from "react-jss"

import BackgroundText from "../../../BackgroundText"
import Panel from "../../../Panel"

import style from "./style"

const PowerUpTeamUp = ({
  classes,
  content: {
    powerupTeamup: { powerupCopy, teamupCopy },
  },
}) => (
  <Panel>
    <div className={cx("container")} style={{ zIndex: 2 }}>
      <div className={cx("row")}>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/powerUp.svg" />
              </LazyLoad>
            </div>
            <h3 className={cx(classes.title, classes.red)}>Game Portfolio</h3>
          </div>
          <span>
            <ul className={classes.bulletList}>
              <li>We leverage our expertise in game development and publishing to scale existing titles, expand their reach, and launch new games.</li>
            </ul>
          </span>
        </div>
        <div className={cx("col-xs-12", "col-md-3", classes.dotRowParent)}>
          <div className={cx(classes.dotRow)}>
            <div className={cx(classes.dot, classes.red)}></div>
            <div className={cx(classes.dot, classes.red)}></div>
            <div className={cx(classes.dot, classes.yellow)}></div>
          </div>
        </div>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/teamUp.svg" />
              </LazyLoad>
            </div>

            <h3 className={cx(classes.title, classes.yellow)}>Strategic Partnerships</h3>
          </div>
          <span>
            <ul className={classes.bulletList}>
              <li>We partner with third-party platforms like Apple Arcade and Netflix to launch global hits.</li>
              <li>
               We invest in high-potential games by fully funding their user acquisition (UA) budgets.
              </li>
            </ul>
          </span>
        </div>
        <div className={cx("col-xs-12", "col-md-3", classes.dotRowParent)}>
          <div className={cx(classes.dotRow)}>
            <div className={cx(classes.dot, classes.yellow)}></div>
            <div className={cx(classes.dot, classes.yellow)}></div>
            <div className={cx(classes.dot, classes.blue)}></div>
          </div>
        </div>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2023/08/18193834/power-up-team-up-level-up.png" />
              </LazyLoad>
            </div>

            <h3 className={cx(classes.title, classes.blue)}>Acquisition</h3>
          </div>

          <span>
            <ul className={classes.bulletList}>
              <li>We identify opportunities through market intelligence and our experienced team to diversify and grow our game portfolio.</li>
            </ul>
          </span>
        </div>
      </div>
    </div>

    <BackgroundText
      {...{
        text: ["Pillars"],
        className: classes.backgroundText,
      }}
    />
    <BackgroundText
      {...{
        text: ["Pillars"],
        mobile: true,
        className: classes.backgroundText,
      }}
    />
  </Panel>
)

export default is(style)(PowerUpTeamUp)
